import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuthentication from "./util-use-auth";

const AuthGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    return <Navigate replace to="login" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
