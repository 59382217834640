import React, { Fragment, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import DefaultLayout from "./components/layout-default";
import GuestGuard from "./utils/util-guest-guard";
import AuthGuard from "./utils/util-auth-guard";
import SinglePageGuard from "./utils/util-single-page-guard";
import { LinearProgress } from "@mui/material";

export const renderRoutes = (routes): JSX.Element => (
  <Suspense fallback={<LinearProgress />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={`${route.path?.toString() ?? i}`}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    path: `404`,
    component: lazy(() => import("./pages/page-not-found"))
  },
  {
    path: `garage-vehicles/:uuid`,
    component: lazy(() => import("./external-garage/page-garage-vehicles"))
  },
  {
    guard: GuestGuard,
    path: `login`,
    component: lazy(() => import("./pages/page-login"))
  },
  {
    path: "*",
    guard: AuthGuard,
    layout: DefaultLayout,
    routes: [
      {
        path: "/",
        component: lazy(() => import("./pages/page-home"))
      },
      {
        guard: SinglePageGuard,
        path: `tickets`,
        component: lazy(() => import("./pages/page-tickets"))
      },
      {
        guard: SinglePageGuard,
        path: `zone-editor`,
        component: lazy(() => import("./pages/page-zone-editor"))
      },
      {
        path: `trips`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-trips-list"))
      },
      {
        path: `trips/lookup`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-trip-lookup"))
      },
      {
        path: `trips/:id`,
        component: lazy(() => import("./pages/page-trips-detail"))
      },
      {
        path: `taxi-rides/:id`,
        component: lazy(() => import("./pages/page-taxi-ride-detail"))
      },
      {
        path: `business`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-business-list"))
      },
      {
        path: `business/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-business-create"))
      },
      {
        path: `business/:id`,
        component: lazy(() => import("./pages/page-business-detail"))
      },
      {
        path: `business/:id/edit`,
        component: lazy(() => import("./pages/page-entity-edit"))
      },
      {
        path: `business/:id/payment`,
        component: lazy(() => import("./pages/page-business-payment"))
      },
      {
        path: `users/create`,
        component: lazy(() => import("./pages/page-users-create"))
      },
      {
        path: `vehicles/:id/issue/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-issue-create"))
      },
      {
        path: `users/:id`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-user-detail"))
      },
      {
        path: `users/:id/edit`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-user-edit"))
      },
      {
        path: `invoices`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-invoice-list"))
      },
      {
        path: `invoices/:id`,
        component: lazy(() => import("./pages/page-invoice-detail"))
      },
      {
        path: `invoices/:id/credits/note/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-refund-create"))
      },
      {
        path: `invoices/:id/cancel`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-invoice-cancel"))
      },
      {
        path: `vehicles`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-vehicles"))
      },
      {
        path: `vehicles/:id`,
        component: lazy(() => import("./pages/page-vehicle-detail"))
      },
      {
        path: `r/:id`,
        component: lazy(() => import("./pages/page-redirect"))
      },
      {
        path: `v/:id`,
        component: lazy(() => import("./pages/page-redirect"))
      },
      {
        path: `damages`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-damages"))
      },
      {
        path: `damages/:id`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-damage-detail"))
      },
      {
        path: `vehicles/:id/damages/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-damage-create"))
      },
      {
        path: `repairs/:id`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-repair-detail"))
      },
      {
        path: `issues/:id`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-issue-detail"))
      },
      {
        path: `products/:userId/charge/:tripLegId?`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-charge-product"))
      },
      {
        path: `promocodes`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-promocode-list"))
      },
      {
        path: `promocodes/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-promocode-create"))
      },
      {
        path: `promocodes/:id`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-promocode-edit"))
      },
      {
        path: `fines`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-fines"))
      },
      {
        path: `schedules`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-schedules"))
      },
      {
        path: `interviews`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-interviews"))
      },
      {
        path: `repairs`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-repairs"))
      },
      {
        path: `fines/create`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-fine-create"))
      },
      {
        path: `/fines/create-vehicle-damage/:tripLegUUID`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-fine-vehicle-damage-create"))
      },
      {
        path: `/fines/create-ops-reported/:tripLegUUID?`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-ops-reported-fine"))
      },
      {
        path: `fines/:id`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-fine-detail"))
      },
      {
        path: `dashboard`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-dashboard"))
      },
      {
        path: `pricing`,
        guard: SinglePageGuard,
        component: lazy(() => import("./pages/page-pricing"))
      },
      {
        path: "*",
        component: lazy(() => import("./pages/page-not-found"))
      }
    ]
  }
];

export default routes;
