import { StylesProvider, jssPreset } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { create } from "jss";
import rtl from "jss-rtl";
import { ConfirmOptions, ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollReset from "./components/scroll-reset";
import routes, { renderRoutes } from "./routes";
import { AuthProvider } from "./states/state-auth";
import { createTheme } from "./utils/util-theme";
import useSettings from "./utils/util-use-settings";
import { StyledEngineProvider } from "@mui/material";
import "./styles/global.css";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const CONFIRM_PROVIDER_DEFAULT: ConfirmOptions = {
  confirmationButtonProps: {
    color: "error",
    variant: "contained",
    autoFocus: true
  },
  cancellationButtonProps: {
    color: "error",
    variant: "outlined"
  }
};

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({ theme: settings.theme });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider dense maxSnack={3}>
              <ConfirmProvider defaultOptions={CONFIRM_PROVIDER_DEFAULT}>
                <BrowserRouter>
                  <AuthProvider>
                    <ScrollReset />
                    {renderRoutes(routes)}
                  </AuthProvider>
                </BrowserRouter>
              </ConfirmProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
