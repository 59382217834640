import React, { FC, ReactNode, Suspense, lazy, useState } from "react";

import { makeStyles } from "@mui/styles";

import type { Theme } from "../utils/util-theme";
import { useUser } from "../utils/util-use-auth";
import { reloadPageSafely } from "../utils/util-reload";

const NavBar = lazy(() => import("./nav-bar"));
const TopBarDashboard = lazy(() => import("./top-bar-dashboard"));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    overflow: "hidden",
    height: "100%",
    width: "100%"
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 180
    }
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  content: {
    backgroundColor: theme.palette.background.dark,
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto"
  }
}));
export class ErrorBoundary extends React.Component<
  any,
  { error: Error | undefined; showFallback: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { error: undefined, showFallback: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error, showFallback: true };
  }

  componentDidCatch(error, errorInfo) {
    // reload page on chunk load error, this happens when we deploy a new version
    if (error.name === "ChunkLoadError") {
      reloadPageSafely();
    } else {
      try {
        localStorage.clear();
        sessionStorage.clear();
      } catch (err) {
        console.error("Couldn't delete local and/or sessionstorage:", err);
      }
    }

    console.error("Error:", error, errorInfo);

    this.setState({ error: error, showFallback: true });
  }
  render() {
    if (this.state.showFallback) {
      const errorMessage = this.state?.error?.message || "Unknown error";
      return (
        <div role="alert" style={{ padding: 10 }}>
          <h1>Oh no, it crashed 😕</h1>
          <br />
          <p>
            <strong>{this.state?.error?.name}</strong> occurred on{" "}
            <strong>{window?.location?.pathname}</strong>
          </p>
          <br />
          <h2>
            <a href={window?.location?.href}>
              <strong>Refresh to try again</strong>
            </a>
          </h2>
          <p>If the error persists, please contact our (dev) support team;</p>
          <ol style={{ marginLeft: 20, marginTop: 10, marginBottom: 10 }}>
            <li>Take a screenshot of this page</li>
            <li>
              Explain what you were doing before this error happened (e.g. which
              button you pressed)
            </li>
          </ol>
          <p>Thanks in advance! 🙏</p>
          <br />
          <p>
            <pre style={{ color: "red" }}>{errorMessage}</pre>
          </p>
          <p>
            <pre style={{ whiteSpace: "break-spaces" }}>
              URL: {window?.location?.href}
            </pre>
            <pre style={{ whiteSpace: "break-spaces" }}>
              Full log: {this.state?.error?.stack}
            </pre>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

const DefaultLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
  const { role } = useUser();
  const hasNoMenu = role.manageOwnBusiness || role.viewSingleTrip;

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <TopBarDashboard onMobileNavOpen={() => setIsMobileNavOpen(true)} />
        {hasNoMenu ? null : (
          <Suspense fallback={null}>
            <NavBar
              onMobileClose={() => setIsMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
          </Suspense>
        )}
        <div
          className={classes.wrapper}
          style={hasNoMenu ? { paddingLeft: 0 } : {}}
        >
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default DefaultLayout;
