export function reloadPageSafely() {
  // reload max once every 10 mins to avoid infinite reload loop
  const storedLastReloadDate = sessionStorage.getItem("lastReloadDate");
  const lastReloadDate = storedLastReloadDate
    ? JSON.parse(storedLastReloadDate).lastReloadDate
    : undefined;

  // last reload is less than 10 mins ago so we don't reload
  if (lastReloadDate && lastReloadDate + 10 * 60 * 1000 > Date.now()) {
    return;
  }

  sessionStorage.setItem(
    "lastReloadDate",
    JSON.stringify({ lastReloadDate: Date.now() })
  );
  window.location.reload();
}
