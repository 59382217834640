import type { FC } from "react";
import { ReactComponent as LogoSvg } from "../icons/logo.svg";

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  return <LogoSvg {...props} />;
};

export default Logo;
