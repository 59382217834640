import { enableES5 } from "immer";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-aspect-ratio/aspect-ratio.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import App from "./app";
import { createRoot } from "react-dom/client";
import { SettingsProvider } from "./states/state-settings";

enableES5();

const consoleOverwrite = console.error;

console.error = function filterErrors(msg) {
  const supressedWarnings = ["validateDOMNesting"];
  if (
    (msg instanceof String || msg?.message instanceof String) &&
    !supressedWarnings.some((entry) =>
      msg?.message ? msg.message.includes(entry) : msg?.includes(entry)
    )
  ) {
    consoleOverwrite.apply(console, arguments);
  }
};
const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <SettingsProvider>
    <App />
  </SettingsProvider>
);
