export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const apiConfig = {
  baseUrl: process.env.REACT_APP_BO_API_URL,
  baseMainAPIUrl: process.env.REACT_APP_MAIN_API_URL,
  garageAPIUrl: process.env.REACT_APP_GARAGE_API_URL,
  businessAPIUrl: process.env.REACT_APP_BUSINESS_API_URL,
};

export const mapboxConfig = {
  token: process.env.REACT_APP_MAPBOX_PUBLIC_KEY
};

export const googleMapsConfig = {
  token: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
};

type ENV = "staging" | "development" | "production";
export const environment = process.env.REACT_APP_ENVIRONMENT as ENV;
