import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useReducer,
  useState
} from "react";
import SplashScreen from "../components/splash-screen";
import { apiConfig } from "./../config";
import { Entity } from "./../types/entity";
import { Role } from "./../types/role";
import type { Authentication, User } from "./../types/user";
import * as firebaseAuth from "firebase/auth";
import { getFirebaseApp } from "../utils/util-firebase";
import { BASE_URL } from "../constants";
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { reloadPageSafely } from "../utils/util-reload";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  isError: boolean;
  avatar?: string;
  user: Authentication | null;
  role: Role | null;
  business: Entity | null;
}

interface AuthContextValue extends AuthState {
  signInWithGoogle: () => Promise<any>;
  signInWithEmail: (email: string) => Promise<void>;
  logout: () => Promise<void>;
}

type Action = {
  type: "AUTH_STATE_CHANGED";
  payload: {
    isAuthenticated: boolean;
    isError?: boolean;
    avatar?: string;
    user: Authentication | null;
    role: Role | null;
    business: Entity | null;
  };
};

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isError: false,
  user: null,
  role: null,
  business: null
};

async function fetchRole(token: string): Promise<Role | null> {
  const response = await fetch(`${apiConfig.baseUrl}/users/me/permissions`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw Error(errorMessage?.message || "Unknown error on login");
  }

  return response.json();
}

async function fetchUser(token: string): Promise<User | null> {
  const response = await fetch(`${apiConfig.baseUrl}/users/me`, {
    headers: {
      authorization: `Bearer ${token}`
    }
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    throw Error(errorMessage?.message || "Unknown error on login");
  }

  return response.json();
}

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "AUTH_STATE_CHANGED": {
      const { isAuthenticated, user, role, business, avatar } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        role,
        business,
        avatar
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  signInWithGoogle: () => Promise.resolve(),
  signInWithEmail: async () => {},
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [isSigninInByEmail, setIsSigninInByEmail] = useState(false);

  // make sure firebase was initialized
  getFirebaseApp();

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebaseAuth.GoogleAuthProvider();
    return firebaseAuth.signInWithPopup(firebaseAuth.getAuth(), provider);
  };

  const logout = async (): Promise<void> => {
    const result = await firebaseAuth.signOut(firebaseAuth.getAuth());

    enqueueSnackbar(`You're successfully logged out`, {
      variant: "default"
    });
    return result;
  };

  async function signInWithEmail(email: string) {
    try {
      // clear local storage before signing in, to avoid any potential issues
      localStorage.clear();
      sessionStorage.clear();
      // clear cookies
      document.cookie = "";

      await firebaseAuth.sendSignInLinkToEmail(firebaseAuth.getAuth(), email, {
        url: window.location.origin,
        handleCodeInApp: true
      });
      localStorage.setItem("emailForSignIn", email);
      enqueueSnackbar(
        `Authentication email sent! Check your mail box (${email}). You can safely close this page now.`,
        {
          variant: "success",
          autoHideDuration: 10000
        }
      );
    } catch (error) {
      enqueueSnackbar(JSON.stringify(error), {
        variant: "error",
        autoHideDuration: 10000
      });
    }
  }

  async function handleEmailSignIn(url: string) {
    if (!firebaseAuth.isSignInWithEmailLink(firebaseAuth.getAuth(), url)) {
      return;
    }

    let email = localStorage.getItem("emailForSignIn");
    if (!email) {
      email = prompt("Please provide your email for confirmation");
    }

    try {
      setIsSigninInByEmail(true);
      const response = await firebaseAuth.signInWithEmailLink(
        firebaseAuth.getAuth(),
        email || "",
        url
      );
      const { user } = response;
      localStorage.removeItem("emailForSignIn");
      return user;
    } catch (error) {
      if (error.code === "auth/invalid-action-code") {
        enqueueSnackbar(
          `Sign-in link is invalid or has expired.\n\nPlease try again to "Sign in with email" to get a new link. Make sure to use the newest link in your mailbox.`,
          {
            variant: "error",
            autoHideDuration: 10000
          }
        );
      } else {
        enqueueSnackbar(JSON.stringify(error), {
          variant: "error",
          autoHideDuration: 10000
        });
      }
    } finally {
      setIsSigninInByEmail(false);
    }
  }

  useEffect(() => {
    const url = window.location.href;
    handleEmailSignIn(url);

    const unsubscribe = firebaseAuth.onAuthStateChanged(
      firebaseAuth.getAuth(),
      async (firebaseUser) => {
        if (!firebaseUser) {
          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated: false,
              user: null,
              role: null,
              business: null
            }
          });
        } else {
          try {
            const token = await firebaseUser.getIdToken();
            const role = await fetchRole(token);

            const isAuthenticated = token && Boolean(role);

            if (!isAuthenticated) {
              dispatch({
                type: "AUTH_STATE_CHANGED",
                payload: {
                  avatar: undefined,
                  isAuthenticated: false,
                  user: null,
                  role: null,
                  business: null
                }
              });
              return;
            }

            // if the role is not businessOwner, we can already continue by loading the homepage (because the users/me call can sometimes take 1-2s)
            // otherwise, we need to fetch the user and business data to be able to redirect the user to their business profile
            if (!role?.manageOwnBusiness) {
              dispatch({
                type: "AUTH_STATE_CHANGED",
                payload: {
                  isAuthenticated,
                  avatar: firebaseUser.photoURL || undefined,
                  user: {
                    email: firebaseUser.email!,
                    name: firebaseUser.displayName || firebaseUser.email!,
                    token
                  },
                  role,
                  business: null
                }
              });
            }

            // fetch user and business data
            const user = await fetchUser(token);
            const business = user?.Entity.find(
              (entity) => entity.type === "business"
            );

            dispatch({
              type: "AUTH_STATE_CHANGED",
              payload: {
                isAuthenticated,
                avatar: firebaseUser.photoURL || undefined,
                user: {
                  email: firebaseUser.email!,
                  name: firebaseUser.displayName || firebaseUser.email!,
                  token,
                  uuid: user!.uuid,
                  ...user
                },
                role,
                business: business || null
              }
            });
          } catch (error) {
            if (error.message === "Failed to fetch") {
              reloadPageSafely();
            }
            if (error.message === "User does not have permissions") {
              // tell user to try via Sign in with Google
              enqueueSnackbar(
                `Please use "Sign in with Google" to login. If you're still having trouble, please contact the Poppy team.`,
                {
                  variant: "error",
                  autoHideDuration: 10000
                }
              );
            }
            enqueueSnackbar(`Login error: ${error.message}`, {
              variant: "error",
              autoHideDuration: 10000
            });
            dispatch({
              type: "AUTH_STATE_CHANGED",
              payload: {
                avatar: undefined,
                isAuthenticated: false,
                isError: true,
                user: null,
                role: null,
                business: null
              }
            });
          }
        }
      }
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enqueueSnackbar]);

  if (state.isError) {
    return <Navigate replace to={`${BASE_URL}/login`} />;
  }
  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signInWithGoogle,
        signInWithEmail,
        logout
      }}
    >
      {isSigninInByEmail ? <SplashScreen /> : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
