import { initializeApp, getApps } from "firebase/app";
import { firebaseConfig } from "../config";
let app;
export function getFirebaseApp() {
  if (app) {
    return app;
  }
  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
  }
}
