import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

import useAuthentication from "./util-use-auth";

const GuestGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthentication();
  if (isAuthenticated) {
    return <Navigate replace to={".."} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
